<template>
  <div class="merchant-list">
    <vs-row class="items-start">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vx-card class="custom-vx-card">
          <vs-button flat v-round class="flex primary ml-auto mr-5" @click="$router.push({ name: 'partners-create' })">
            Add merchant
          </vs-button>
          <vs-table :data="partners" class="mt-5">
            <template slot="header">
              <vs-select class="ml-5 mb-4" label="Show records" v-model="limit">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in limitValue" />
              </vs-select>
            </template>
            <template slot="thead">
              <vs-th>Company</vs-th>
              <vs-th>Merchant ID</vs-th>
              <vs-th>Status</vs-th>
              <vs-th></vs-th>
              <vs-th></vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.companyName">{{ tr.companyName }}</vs-td>
                <vs-td :data="tr.merchantId">{{ tr.merchantId }}</vs-td>
                <vs-td :data="tr.accountStatus">{{ checkAccountStatus(tr) }}</vs-td>
                <vs-td :data="tr._id">
                  <vs-button v-if="tr.accountStatus == 'Active'" flat v-round class="flex primary ml-auto mr-5" @click="partnerLogin(tr._id)">
                    Login
                  </vs-button>
                </vs-td>
                <vs-td :data="tr">
                  <u><a @click="handleEdit(tr._id)">Edit</a></u>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div class="mt-3">
            <v-pagination v-model="pageNumber" :page-count="totalPage"></v-pagination>
          </div>
        </vx-card>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import vPagination from "@/views/components/pagination";
export default {
  components: { vPagination },
  data() {
    return {
      limitValue: [
        { text: "1", value: 1 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      clientUrl: process.env.VUE_APP_CLIENT_API,
      pageNumber: 1,
      totalData: 0,
      limit: 50,
      partners: [],
    };
  },
  methods: {
    ...mapActions("partner", ["fetchPartners", "deletePartner"]),

    checkAccountStatus(tr) {
      if (tr.accountStatus != "Active") {
        if (!tr.requestOptions || tr.requestOptions.length == 0) {
          return "Inactive";
        }

        return "Ready to Activate";
      }

      return tr.accountStatus;
    },
    async getListSetting() {
      const payload = {
        name: this.$route.name,
        partnerId: JSON.parse(localStorage.getItem("user"))._id,
      };
      await this.$store.dispatch("listSetting/updatelistSetting", payload).then((res) => {
        this.limit = parseInt(res.data.data.limit) || this.limit;
      }).catch((ex) => {});
    },
    async saveListSetting(limit) {
      const payload = {
        name: this.$route.name,
        limit,
        partnerId: JSON.parse(localStorage.getItem("user"))._id,
      };

      await this.$store.dispatch("listSetting/updatelistSetting", payload).then((res) => {}).catch((ex) => {});
    },
    async getPartners(page, limit = this.limit) {
      const params = `?page=${page}&limit=${limit}&sort=desc`;

      await this.fetchPartners(params).then((res) => {
        this.partners = res.data.data.docs;
        this.totalData = res.data.data.pagination.total ? res.data.data.pagination.total : 0;
      })
      .catch((ex) => {});
    },
    partnerLogin(partnerId) {
      let client = `${this.clientUrl}login-as-partner?token=${localStorage.getItem("accessToken")}&partnerId=${partnerId}`;
      window.open(client, "_blank");
    },
    viewDetail(partner) {
      return this.$router.push({
        name: "partners-detail",
        params: { id: partner._id },
      });
    },
    handleEdit(id) {
      return this.$router.push({
        name: "partners-edit",
        params: { id: id },
      });
    },
    confirmDeleteAlert(id) {
      let self = this;
      Swal.fire({
        title: "Are you sure?",
        text: "The merchant will be permanently deleted.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          self.handleDelete(id);
        }
      });
    },
    async handleDelete(id) {
      this.$vs.loading();
      await this.deletePartner(id).then((result) => {
        this.$vs.loading.close();
        this.showMessage("Successful", "Merchant has been deleted successfully.", "success", "icon-check-circle");
        this.getPartners(this.pageNumber);
      }).catch((ex) => {
        this.$vs.loading.close();
      });
    },
    showMessage(title = "Successful", message = "Task completed", color = "success", icon = "icon-check-circle") {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },
  },
  watch: {
    pageNumber(val) {
      this.getPartners(val, this.limit);
    },
    limit(val) {
      this.pageNumber = 1;
      this.getPartners(this.pageNumber, val);
      this.saveListSetting(val);
    },
  },
  created() {
    this.getListSetting();
    this.getPartners(this.pageNumber, this.limit);
  },
  computed: {
    totalPage: function () {
      return Math.ceil(this.totalData / this.limit);
    },
  },
};
</script>

